import React, { lazy, Suspense } from 'react'
import { Provider } from 'jotai'
import { Router } from 'react-router-dom'
import { CompatRouter, Route, Routes } from 'react-router-dom-v5-compat'
import { PageContentSlot } from '@wf-mfe/layout'
import { history } from '@wf-mfe/navigation'
import { useIsTreatmentEnabled } from '@wf-mfe/toggles'
import { routes } from '../utils/constants'

const LazyNotificationCenter = lazy(() => import('./notification-center'))
const LazyShimmedAnnouncements = lazy(() => import('./shims/announcements'))
const LazyShimmedNotifications = lazy(() => import('./shims/notifications'))

const NotificationCenter = () => (
  <Suspense fallback="">
    <LazyNotificationCenter />
  </Suspense>
)

const ShimmedAnnouncements = () => (
  <Suspense fallback="">
    <LazyShimmedAnnouncements />
  </Suspense>
)

const ShimmedNotifications = () => (
  <Suspense fallback="">
    <LazyShimmedNotifications />
  </Suspense>
)

export const NotificationsPage = () => {
  const isUnshimmed = useIsTreatmentEnabled('unshim-announcements-notifications')
  return isUnshimmed ? <NotificationCenter /> : <ShimmedNotifications />
}

export default function NotificationCenterRouter() {
  return (
    <Provider>
      <PageContentSlot mfeName="@wf-mfe/notification-hub">
        <Router history={history}>
          <CompatRouter>
            <Routes>
              <Route path={`${routes.notificationsLink}`} element={<NotificationsPage />} />
              <Route
                path={`${routes.announcementsLink}/:subPath?`}
                element={<ShimmedAnnouncements />}
              />
            </Routes>
          </CompatRouter>
        </Router>
      </PageContentSlot>
    </Provider>
  )
}
