import React from 'react'
import { node, object, string } from 'prop-types'
import { cx, css } from 'emotion'
import { Link } from 'react-router-dom-v5-compat'
import { stylesByTagName } from '@phoenix/all'

export default function NotificationLink({ children, className = '', forwardedRef, ...rest }) {
  const sharedProps = { className: cx(css(stylesByTagName.a), className), ref: forwardedRef }

  if (rest.to) {
    return (
      <Link {...sharedProps} {...rest}>
        {children}
      </Link>
    )
  }
  return (
    <a {...sharedProps} {...rest}>
      {children}
    </a>
  )
}

NotificationLink.propTypes = { children: node, className: string, forwardedRef: object }
