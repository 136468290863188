import React from 'react'
import { useParams } from 'react-router-dom-v5-compat'
import { primary } from '@phoenix/all'
import { PageContent } from '@wf-mfe/layout'
import { routes } from '../../utils/constants'

const spectrumStyles = `
  #content-announcements .main h1 {
    font-family: Adobe Clean;
    font-weight: 700 !important;
    font-size:  1.5rem !important;
    background-color: ${primary.gray(75)};
  }

  #content-announcements .main > div h1 {
    margin-left: 0px !important;
    padding-left: 12px !important;
  }
`

const quicksilverToAngularMap = {
  inbox: '#!/inbox',
  favorites: '#!/favorites',
  sent: '#!/sent',
  drafts: '#!/drafts',
  deleted: '#!/deleted',
  new: '#!/new',
}

function getUrl(subPath) {
  if (subPath) {
    if (/[\da-f]{32}/.test(subPath)) {
      return `#!/inbox/${subPath}`
    }
    return quicksilverToAngularMap[subPath]
  }
}

export default function ShimmedAnnouncements() {
  const { subPath } = useParams()
  const redrockAnnouncementLocation = getUrl(subPath)

  return (
    <PageContent
      basePathname={routes.announcementsLink}
      legacyStyles
      styles={`
        .from-image.flt-l {
          top: 0 !important;
          left: 0 !important;
        }
        ${spectrumStyles}
      `}
      tile="content-announcements"
      urlAppend={redrockAnnouncementLocation}
    />
  )
}
