import React from 'react'
import { arrayOf, bool, func, string } from 'prop-types'
import { useNavigate } from 'react-router-dom-v5-compat'
import { cx } from 'emotion'
import { parseISO } from 'date-fns'
import { DocumentVersion, ProofApproval } from 'workfront-objcodes'
import { tx } from 'workfront-twind'
import { Avatar, Ellipse, Text, Tooltip } from '@phoenix/all'
import { getMessageParams, useLocalizations } from '@productivity/i18n'
import { addTestId, checkForEnterKeyPress } from '@productivity/utils'
import NotificationLink from '../../shared/notification-link'
import { messageKeys, testIds } from '../../utils/constants'
import onClickUnlessSelection from '../../utils/on-click-unless-selection'
import { documentShape, parentShape } from '../../utils/prop-types'
import { formatTimestamp, parseDate } from '../../utils/timestamp-helper'
import ObjectIcon from './object-icon'
import * as styles from './styles'

const linesToClamp = 2
const footerLinesToClamp = 1

export function NotificationItem({
  avatar,
  condensedDisplay = false,
  description,
  documents,
  eventType,
  from,
  href,
  ID,
  isRead,
  objCode,
  parent,
  time,
  title,
  toggleHub,
  toggleRead,
  type,
  allowExternalLinking = false,
}) {
  const navigate = useNavigate()
  const [labels] = useLocalizations(
    getMessageParams({
      markRead: messageKeys.markSeen,
      markUnread: messageKeys.markUnseen,
      notification: messageKeys.notification,
      yesterday: messageKeys.yesterday,
    })
  )

  const handleItemClick = onClickUnlessSelection((ev) => {
    // Close hub
    toggleHub()
    // Toggle Notification as read
    //
    // TODO: Don't toggle as read if user clicked on the parent element.
    // Can be done using refs but, this requires @phoenix/type to support "forwardRef".
    toggleRead()

    // Don't push new history on anchor ('a') tags' event bubbling.
    // This could cause a undesired double tab opening bug.
    const target = ev.target
    if (target.nodeName === 'A') return

    // Push to new url
    // This handles clicks on the full notification item. (not an <a> tag)
    if (href) {
      const isCmdCtrl = 'MacIntel' === window.navigator.platform ? ev.metaKey : ev.ctrlKey
      if (isCmdCtrl || objCode === ProofApproval) {
        window.open(href, '_blank')
      } else {
        navigate(href)
      }
    }
  })
  const day = parseISO(time)

  return (
    <div
      {...addTestId(`${testIds.notificationItem}${ID}`)}
      role="presentation"
      aria-label={labels.notification}
      className={cx(styles.wrapper, condensedDisplay && styles.condensedPadding)}
      onClick={handleItemClick}
      onKeyDown={(event) => {
        if (checkForEnterKeyPress(event.keyCode)) {
          handleItemClick(event)
        }
      }}
    >
      <button
        {...addTestId(testIds.notificationItemUnread)}
        className={styles.readContainer}
        style={{ display: 'flex' }}
        aria-label={labels.markRead}
        onClick={(ev) => {
          ev.stopPropagation()
          toggleRead()
        }}
      >
        <Tooltip
          content={isRead ? labels.markUnseen : labels.markRead}
          // Style Tooltip's wrapping span
          // This is done so the tooltip is
          // positioned correctly to the icon height
          className={cx(styles.readIcon, !isRead && styles.unreadIcon)}
        />
      </button>
      <div className={styles.avatar} {...addTestId(testIds.avatarContainer)}>
        <Avatar type="medium" label={from} avatarURL={avatar} imageTabIndex={-1} />
      </div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <div>
            {parent && (
              <NotificationLink
                {...addTestId(testIds.parentLink)}
                to={parent.link}
                className={styles.parent}
                style={{ display: 'inline-block' }}
              >
                <Ellipse lineClamp={linesToClamp} text={parent.title} />
              </NotificationLink>
            )}
            <NotificationLink
              {...addTestId(testIds.titleLink)}
              className={styles.title}
              {...([DocumentVersion, ProofApproval].includes(objCode) && {
                href,
                target: '_blank',
              })}
              {...(allowExternalLinking && { 'data-unified-shell-ignore': true })}
              {...(![DocumentVersion, ProofApproval].includes(objCode) && { to: href })}
            >
              <Ellipse lineClamp={linesToClamp} text={title} />
            </NotificationLink>
          </div>
          <Tooltip
            content={parseDate(day, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
            css="display: inherit;"
          >
            <Text.Small>{formatTimestamp(day, labels.yesterday)}</Text.Small>
          </Tooltip>
        </div>
        {description && (
          <Text tagName="p">
            <Ellipse lineClamp={linesToClamp} text={description} />
          </Text>
        )}
        {Array.isArray(documents) && documents.length > 0 && (
          <>
            <ul>
              {documents.map((doc) => (
                <img
                  {...addTestId(doc.ID)}
                  key={doc.ID}
                  alt=""
                  className={styles.img}
                  src={`/internal/document/preview?ID=${doc.ID}`}
                />
              ))}
            </ul>
          </>
        )}

        <div className={styles.footer}>
          <div data-testid="footer-message-container">
            {type && (
              <span className={styles.type}>
                <div className={styles.icon}>
                  <ObjectIcon itemObjCode={objCode} eventType={eventType} />
                </div>
                <span className={tx(`leading-small text-75`)}>
                  <Tooltip content={type} css="display: inherit;">
                    <Ellipse lineClamp={footerLinesToClamp} text={type} />
                  </Tooltip>
                </span>
              </span>
            )}
          </div>
          <div data-testid="footer-owner-container">
            {from && (
              <span className={tx(styles.from, `leading-small text-75`)}>
                <Tooltip content={from} css="display: inherit;">
                  <Ellipse lineClamp={footerLinesToClamp} text={from} />
                </Tooltip>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

NotificationItem.propTypes = {
  avatar: string,
  condensedDisplay: bool,
  description: string,
  documents: arrayOf(documentShape),
  eventType: string,
  from: string,
  href: string,
  ID: string,
  isRead: bool,
  objCode: string,
  parent: parentShape,
  time: string,
  title: string,
  toggleHub: func,
  toggleRead: func.isRequired,
  type: string,
  allowExternalLinking: bool,
}
